import Alpine from 'alpinejs';
 
window.Alpine = Alpine
 
Alpine.start()

window.onload = function () {
	console.log("load completed on " + window.navigator.platform + " / " + window.navigator.vendor + "\n\t\t~ " + window.navigator.userAgent);
};

